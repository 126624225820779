import { request } from './utils';

import {
  WithdrawalsResponse,
  GetWithdrawalsRequestOptions,
  CreateWithdrawalRequestOptions,
  SucceededResponse,
  WithdrawalOverviewResponse
} from './interfaces';

export function getWithdrawalOverview(): Promise<WithdrawalOverviewResponse> {
  return request({
    url: '/withdrawals/overview',
    method: 'get'
  });
}

export function getWithdrawals({ query }: GetWithdrawalsRequestOptions): Promise<WithdrawalsResponse> {
  return request({
    url: '/withdrawals',
    method: 'get',
    params: query
  });
}

export function createWithdrawal({ data }: CreateWithdrawalRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/withdrawals',
    method: 'post',
    data
  });
}
