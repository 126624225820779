import { reactive, unref } from 'vue';
import { useQuery } from 'vue-query';

import { getPlans } from '@/apis';
import { UsePlansOptions } from './interfaces';

export function usePlans({ page, pageSize }: UsePlansOptions = {}) {
  return useQuery(
    reactive(['plans', { page, pageSize }]),
    () => getPlans({ query: { page: unref(page), pageSize: unref(pageSize) } }),
    { keepPreviousData: true }
  );
}
