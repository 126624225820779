import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);

export enum TimeZoneType {
  TAIPEI_TIME = 'Asia/Taipei',
  LONDON_TIME = 'Europe/London'
}

export const getUserTimeZone = () => {
  return dayjs.tz.guess();
};

export const formatLocalTime = (time: string, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!time) return;

  const TIME_FORMAT = format;
  const customTimeZone = getUserTimeZone();
  return dayjs(time).tz(customTimeZone).format(TIME_FORMAT);
};

export const formatISOString = (dateTime: string) => {
  return dateTime && dayjs(dateTime).toISOString();
};
