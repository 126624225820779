import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getUser,
  getUsers,
  updateUser,
  GetUserRequestOptions,
  UpdateUserRequestOptions,
  UserResponse,
  CreateUserRequestOptions,
  createUser,
  deleteUser,
  DeleteUserRequestOptions
} from '@/apis';
import { UseUsersOptions } from './interfaces';

export function useUser(options: GetUserRequestOptions, useOptions: Omit<UseQueryOptions<UserResponse, unknown, UserResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('user', () => getUser(options), useOptions);
}

export function useUsers({ page, keyword, isNFTMember, verifiedStatus, registerStartAt, registerEndAt, pageSize, sort, sortKey }: UseUsersOptions = {}) {
  return useQuery(
    reactive(['users', { page, keyword, isNFTMember, verifiedStatus, registerStartAt, registerEndAt, pageSize, sort, sortKey }]),
    () => getUsers({ query: { page: unref(page), keyword: unref(keyword), isNFTMember: unref(isNFTMember), verifiedStatus: unref(verifiedStatus), registerStartAt: unref(registerStartAt), registerEndAt: unref(registerEndAt), pageSize: unref(pageSize), sort: unref(sort), sortKey: unref(sortKey) } }),
    { keepPreviousData: true }
  );
}

export function useCreateUser() {
  return useMutation((options: CreateUserRequestOptions) => createUser(options));
}

export function useUpdateUser() {
  return useMutation((options: UpdateUserRequestOptions) => updateUser(options));
}

export function useDeleteUser() {
  return useMutation((options: DeleteUserRequestOptions) => deleteUser(options));
}
