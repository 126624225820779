import { reactive, unref } from 'vue';
import { useQuery } from 'vue-query';

import { getDailyCampaignStatistics } from '@/apis';
import { UseDailyCampaignStatisticsOptions } from './interfaces';

export function useDailyCampaignStatistics({ page, pageSize, channelIds, sort, sortKey, startDate, endDate }: UseDailyCampaignStatisticsOptions = {}, { enabled }) {
  return useQuery(
    reactive(['dailyCampaignStatistics', { page, pageSize, channelIds, sort, sortKey, startDate, endDate }]),
    () => getDailyCampaignStatistics({ query: { page: unref(page), pageSize: unref(pageSize), sort: unref(sort), startDate: unref(startDate), sortKey: unref(sortKey), endDate: unref(endDate), channelIds: unref(channelIds) } }),
    { keepPreviousData: true, enabled }
  );
}
