import { CreateWithdrawalRequestOptions } from '@/apis';
import { createWithdrawal, getWithdrawals } from '@/apis/withdrawal';
import { reactive, unref } from 'vue';
import { useQuery, useMutation } from 'vue-query';

import { UseWithdrawalsOptions } from './interfaces';

export function useWithdrawals({ page, pageSize, sort, sortKey }: UseWithdrawalsOptions = {}) {
  return useQuery(
    reactive(['withdrawals', { page, pageSize, sort, sortKey }]),
    () => getWithdrawals({ query: { page: unref(page), pageSize: unref(pageSize), sort: unref(sort), sortKey: unref(sortKey) } }),
    { keepPreviousData: true }
  );
}

export function useCreateWithdrawal() {
  return useMutation((options: CreateWithdrawalRequestOptions) => createWithdrawal(options));
}
