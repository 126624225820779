
import { defineComponent, ref } from 'vue';

import { formatLocalTime } from '@/utils/format-time';
import { useChannels } from '@/composables/api';

export default defineComponent({
  setup() {
    // query items
    const page = ref(1);
    const pageSize = ref(30);

    const { data, isLoading, isFetching } = useChannels({ page, pageSize });

    return {
      page,
      data,
      isLoading,
      isFetching,
      formatLocalTime
    };
  }
});
