import { ChannelIdOption, GetCampaignCommissionOverview, getCampaignCommissions, getChannelCampaigns, getChannels } from '@/apis';
import { reactive, unref } from 'vue';
import { useQuery } from 'vue-query';

import { UseCampaignCommissionOverviewOptions, UseCampaignCommissionsOptions, UseChannelCampaignsOptions, UseChannelsOptions } from './interfaces';

export function useChannelCampaigns({ channelId }: ChannelIdOption, { page, pageSize }: UseChannelCampaignsOptions = {}) {
  return useQuery(
    reactive(['channelCampaigns', { page, pageSize }]),
    () => getChannelCampaigns({
      channelId,
      query: { page: unref(page), pageSize: unref(pageSize) }
    }),
    { keepPreviousData: true }
  );
}

export function useChannels({ page, pageSize }: UseChannelsOptions = {}) {
  return useQuery(
    reactive(['channels', { page, pageSize }]),
    () => getChannels({ query: { page: unref(page), pageSize: unref(pageSize) } }),
    { keepPreviousData: true }
  );
}

export function useCampaignCommissions({ page, pageSize, channelIds }: UseCampaignCommissionsOptions = {}) {
  return useQuery(
    reactive(['campaignCommissions', { page, pageSize, channelIds }]),
    () => getCampaignCommissions({ query: { page: unref(page), pageSize: unref(pageSize), channelIds: unref(channelIds) } }),
    { keepPreviousData: true }
  );
}

export function useCampaignCommissionOverview({ channelIds }: UseCampaignCommissionOverviewOptions = {}) {
  return useQuery(
    reactive(['campaignCommissionOverview', { channelIds }]),
    () => GetCampaignCommissionOverview({ query: { channelIds: unref(channelIds) } }),
    { keepPreviousData: true }
  );
}
