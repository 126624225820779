import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';

import {
  getPayee,
  updatePayee,
  UpdatePayeeRequestOptions,
  PayeeResponse
} from '@/apis';

export function usePayee(useOptions: Omit<UseQueryOptions<PayeeResponse, unknown, PayeeResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('payee', () => getPayee(), useOptions);
}

export function useUpdatePayee() {
  return useMutation((options: UpdatePayeeRequestOptions) => updatePayee(options));
}
